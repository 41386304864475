h1 {
    margin-top: 0;
    padding-top: 20px;
    color: white;
    font-size: xxx-large;
    display: block;
}

.btn-type1 {
    background-color: #04AA6D;
    color: white;
    padding: 8px 12px;
    margin: 10px;
    border: none;
    border-radius: 5px;
    cursor: pointer;
    font-size: larger;
}

.btn-type1:disabled {
    background-color: #797979;
}

.btn-type1:not(:disabled):hover {
    background-color: #037f58;
}

.form-box {
    padding: 20px;
    background-color: #00000080;
    border: 1px solid #ddd;
}

label {
    color: white;
    font-size: 1.3em;
}

input[type="text"] {
    padding: 7px;
}