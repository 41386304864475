.canvas-container {
    position: relative;
    display: inline-block;
}

canvas{
    display: block;
}

#canvas-back-btn {
    position: absolute;
    top: 10px;
    left: 16px;
    font-size: small;
    margin: 2px;
    padding: 5px;
}
