html, body {
  margin: 0;
  padding: 0;
}

.App {
  text-align: center;
  font-family: Arial, sans-serif;

  background-image: url("../resources/images/space-cropped.jpg");
  background-size: cover;
  background-position: center;
  background-repeat: no-repeat;
  height: 100vh;
  width: 100vw;
}

.room-gui {
  display: inline-block;
  height: auto;
  padding-right: 20px;
}

.game_explanation {
  display: inline-block;
  background-color: #00000080;
  padding: 20px;
  border: 1px solid #ddd;
}

.room-explanation-wrapper {
  display: flex;
  justify-content: center;
}

.controls {
  margin-top: 10px; /* Space between the table and this div */
}

table {
  margin: 0 auto;
  border-collapse: collapse;
  width: 550px;
}

input {
  padding: 10px;
  margin: 10px;
  border: 1px solid #ddd;
  border-radius: 4px;
}

p {
  color: white;
  font-size: larger;
}