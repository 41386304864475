#header-wrapper {
    padding-top: 10px;
    display: flex;
    flex-direction: row;
    justify-content: center;
}

#header-wrapper h1 {
    margin-right: 15px;
    border: none;
}

#room-code-wrapper {
    margin-left: 15px;
    display: flex;
    justify-content: center;
}

#room-code-header {
   color: rgb(179, 179, 179);
   border: none;
   margin-top: 0;
   margin-bottom: 35px;
   margin-right: 10px; 
   padding: 23px;
}

#room-code-header span {
    color: white;
    font-size: xx-large;
}

#join-by-code-wrapper {
    border: none;
    padding: 0px;
    height: 92px;
}

#join-by-code-wrapper h3 {
    color: rgb(179, 179, 179);
    margin-top: 9px;
    margin-bottom: 5px;
}

#join-by-code-wrapper input {
    color: black;
    background-color: white;
    margin-top: 5px;
    margin-bottom: 10px;
    padding: 4px;
    width: 70px;
    height: 22px;
    font-size: large;
    border: 1px solid white;
    border-radius: 0;
    text-transform: uppercase;
}

#join-by-code-wrapper input:focus-visible {
    outline: none;
    border: 1px solid lime;
} 

th, td {
    border: 1px solid #ddd;
    padding: 8px;
    text-align: left;
}

th {
    background-color: #04AA6D;
    color: white;
}

th:nth-child(1), td:nth-child(1) {
    width: 40%;
}

th:nth-child(2), td:nth-child(2) {
    width: 20%;
}

th:nth-child(3), td:nth-child(3) {
    width: 20%;
}

tr:nth-child(odd) {
    background-color: #f2f2f2;
}

tr:nth-child(even) {
    background-color: #ffffff;
}

.other-column {
    align-content: center;
}

.other-column button {
    font-size: smaller;
    padding: 5px;
    margin: 0;
}

.other-column #kick-btn {
    margin-right: 10px;
}

.bold {
    font-weight: bold;
}

.secondary {
    color: gray;
    font-size: small;
}

#copy-icon {
    color: #04AA6D;
    margin-left: 6px;
    position: relative;
    top: -3px;
}

#copy-icon:hover {
    color: #027a55;
}

#join-btn:not(:disabled):hover {
    color: #027a55;
}

#join-btn {
    color: #04AA6D;
    background-color: rgba(0, 0, 0, 0);
    border: none;
    font-size: 26px;
    margin-right: 8px;
    position: relative;
    top: 3px;
}

#join-btn:disabled {
    color: #797979;
}

#ready-btn {
    width: 104px;
}

#playername-input {
    width: 130px;
    position: relative;
    top: -2px;
    padding: 9px;
    margin-right: 8px;
}

.controls button {
    font-size: 18px;
    margin: 9px;
}