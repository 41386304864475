.error-popup {
    position: fixed;
    top: 20px;
    right: 20px;
    padding: 10px 20px;
    color: white;
    border-radius: 5px;
    opacity: 1;
    transition: opacity 0.5s ease-in-out;
  }
  
  .fade-out {
    opacity: 0;
  }