.create-room-wrapper {
    display: flex;
    flex-direction: column;
    align-items: center;
}

.create-room-gui {
    display: flex;
    flex-direction: column;
    max-width: 30%;
}

#max-players-input {
    max-width: 33px;
    margin-left: 7px;
}

#max-players-input-wrapper {
    display: flex;
    justify-content: center;
}

#max-players-input-wrapper label {
    margin: 10px;
    margin-top: 17px;
    margin-left: 5px;
    margin-right: 14px;
    height: 26px;
    font-size: 1.15em;
}

#max-players-input {
    margin-right: 6px;
}

/* Hide the default radio buttons */
input[type="radio"] {
    display: none;
}

/* Custom radio button styles */
input[type="radio"] + label {
    display: inline-block;
    position: relative;
    padding-left: 35px;
    margin-right: 7px;
    margin-top: 10px;
    margin-bottom: 12px;
    cursor: pointer;
    font-size: 1.1em;
    color: #ffffff; /* White color for labels */
}

/* Square box */
input[type="radio"] + label:before {
    content: '';
    position: absolute;
    left: 4px;
    top: 0;
    width: 23px;
    height: 23px;
    border: 2px solid #4CAF50;
    border-radius: 4px;
    background-color: #fff;
}

/* Checkmark (hidden by default) */
input[type="radio"] + label:after {
    content: '✔';
    position: absolute;
    left: 9px;
    top: -1px;
    font-size: 20px;
    color: #4CAF50;
    display: none;
}

/* Show checkmark when the radio button is checked */
input[type="radio"]:checked + label:after {
    display: block;
}